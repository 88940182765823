<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>NOS EXPERTISES</h1>
        <ul>
            <li><a routerLink="/">ACCUEIL</a></li>
            <li>SERVICES</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            
            <!-- Création de Sites Internet -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-website"></i>
                    </div>
                    <h3>Création de Sites Internet</h3>
                    <ul>
                        <li>Sites vitrines</li>
                        <li>Sites e-commerce</li>
                        <li>Pages de vente</li>
                        <li>Sites institutionnels</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/site-web-et-ecommerce" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- Développement Web -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-coding"></i>
                    </div>
                    <h3>Développement Web</h3>
                    <ul>
                        <li>Intégration de CMS</li>
                        <li>Frontend/Backend</li>
                        <li>Applications spécifiques</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/developpement-web" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- SEO & SEA -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>SEO & SEA</h3>
                    <ul>
                        <li>Audit SEO</li>
                        <li>Campagnes SEA</li>
                        <li>Optimisation de contenu</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/publicite-seo-sea" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- Hébergement -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-server"></i>
                    </div>
                    <h3>Hébergement</h3>
                    <ul>
                        <li>Hébergement partagé</li>
                        <li>Serveurs dédiés</li>
                        <li>Hébergement cloud</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/hebergement" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- Maintenance -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-maintenance"></i>
                    </div>
                    <h3>Maintenance</h3>
                    <ul>
                        <li>Mises à jour techniques</li>
                        <li>Support client</li>
                        <li>Sauvegardes et sécurité</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/maintenance" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- Logo & Print -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-design"></i>
                    </div>
                    <h3>Logo & Print</h3>
                    <ul>
                        <li>Création de logos</li>
                        <li>Design de cartes de visite</li>
                        <li>Articles promotionnels</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/logo-and-print" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
