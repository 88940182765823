import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-seo-local-astuces',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './seo-local-astuces.component.html',
  styleUrl: './seo-local-astuces.component.scss'
})
export class SeoLocalAstucesComponent {

  handleLinkClick(event: MouseEvent, route: string) {
    // Annule la navigation et l'action par défaut
    event.preventDefault();
    event.stopImmediatePropagation();
    
    console.log('Aucun changement de page - navigation annulée');
  }

}
