<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Logo & Print Design</h1>
        <ul>
            <li><a routerLink="/">ACCUEIL</a></li>
            <li><a routerLink="/services">SERVICES</a></li>
            <li>Logo & Print Design</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="justified-text services-details-desc">
                        <h3>Logo & Print Design</h3>
                        <p>Chez Neoluxis Digital, nous comprenons l'importance d'un design visuel percutant et cohérent pour renforcer l'image de marque de votre entreprise. Nos services de design de logo et d'impression sont conçus pour offrir des solutions créatives et professionnelles qui captivent et engagent votre audience cible.</p>
                        
                        <h3>Création de Logos</h3>
                        <p>Un logo est la première impression que votre entreprise donne à ses clients. Nos designers talentueux créent des logos uniques qui représentent fidèlement l'identité et les valeurs de votre marque. Chaque logo est conçu pour être mémorable, versatile et intemporel.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Design personnalisé</li>
                            <li><i class="flaticon-tick"></i> Variations pour différentes applications (web, print, etc.)</li>
                            <li><i class="flaticon-tick"></i> Fichiers haute résolution</li>
                            <li><i class="flaticon-tick"></i> Manuel de la marque</li>
                        </ul>

                        <h3>Design pour Éléments Publicitaires</h3>
                        <p>Pour une promotion efficace de votre entreprise, nous offrons des services de design pour divers éléments publicitaires. Nos créations sont conçues pour attirer l'attention et communiquer votre message de manière claire et impactante.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> T-shirts et casquettes personnalisés</li>
                            <li><i class="flaticon-tick"></i> Flyers, brochures et affiches</li>
                            <li><i class="flaticon-tick"></i> Bannières et stands pour événements</li>
                            <li><i class="flaticon-tick"></i> Articles promotionnels (stylos, tote bags, etc.)</li>
                        </ul>

                        <h3>Design d'Impression</h3>
                        <p>Nos services de design d'impression couvrent une large gamme de besoins, des cartes de visite aux affiches grand format. Nous veillons à ce que chaque élément imprimé soit de la plus haute qualité, avec une attention particulière aux détails et à la finition.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Cartes de visite professionnelles</li>
                            <li><i class="flaticon-tick"></i> Papeterie (en-têtes, enveloppes, etc.)</li>
                            <li><i class="flaticon-tick"></i> Dépliants et brochures</li>
                            <li><i class="flaticon-tick"></i> Affiches et bannières</li>
                        </ul>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/noeluxis-sweet-1.jpg" alt="Création de Logos neoluxis digital">
                            </div>
                            <div class="item">
                                <img src="assets/img/noeluxis-sweet-2.jpg" alt=" neoluxis digitalDesign pour Éléments Publicitaires">
                            </div>
                            <div class="item">
                                <img src="assets/img/neoluxis-tshirt-1.jpg" alt="Design d'Impression neoluxis digital">
                            </div>

                            <div class="item">
                                <img src="assets/img/neoluxis-tshirt-2.jpg" alt="Design d'Impression neoluxis digital">
                            </div>
                        </div>

                        <h3>Pourquoi Choisir Nos Services</h3>
                        <p>En choisissant Neoluxis Digital, vous bénéficiez de l'expertise de notre équipe de designers passionnés et expérimentés. Nous travaillons en étroite collaboration avec vous pour comprendre vos besoins et créer des designs qui parlent à votre audience.</p>

                        <blockquote class="blockquote">
                            <p>"Élevez votre marque avec des designs percutants et professionnels grâce à Neoluxis Digital."</p>
                        </blockquote>

                        <h3>Les Bénéfices de Nos Services</h3>
                        <p>Nos services de design de logo et d'impression offrent une valeur ajoutée en renforçant la reconnaissance de votre marque et en améliorant votre communication visuelle. Chaque création est pensée pour avoir un impact durable et positif sur votre audience.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Designs uniques et créatifs</li>
                            <li><i class="flaticon-tick"></i> Matériaux de haute qualité</li>
                            <li><i class="flaticon-tick"></i> Cohérence visuelle</li>
                            <li><i class="flaticon-tick"></i> Livraison rapide</li>
                        </ul>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quelle est l'importance d'un bon logo pour une entreprise ?
                                    </a>
                                    <p class="accordion-content show">Un bon logo est crucial pour établir une identité de marque forte et reconnaissable. Il permet de se démarquer de la concurrence et de créer une connexion émotionnelle avec votre audience.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels types d'éléments publicitaires concevez-vous ?
                                    </a>
                                    <p class="accordion-content">Nous concevons une variété d'éléments publicitaires, y compris des t-shirts, casquettes, flyers, brochures, bannières et articles promotionnels comme des stylos et des tote bags.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Offrez-vous des services de design pour des événements ?
                                    </a>
                                    <p class="accordion-content">Oui, nous offrons des services de design pour des bannières, stands et autres supports pour événements, afin de maximiser votre impact lors des salons et conférences.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Comment garantissez-vous la qualité des impressions ?
                                    </a>
                                    <p class="accordion-content">Nous travaillons avec des imprimeurs de confiance et utilisons des matériaux de haute qualité pour garantir que chaque impression est nette, durable et professionnelle.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/expertises/logo-and-print" class="active">Logo & Print <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/site-web-et-ecommerce">Création de Sites Internet et E-commerce <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/developpement-web">Développement Web <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/publicite-seo-sea">Publicité SEO & SEA <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/hebergement">Hébergement <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/maintenance">Maintenance <i class="flaticon-right"></i></a></li>
                            
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Téléchargements</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a routerLink="#">Guide des Stratégies de Marketing Digital Efficaces<i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Complet d'Optimisation SEO & SEA <i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Avancé de Développement Web & Maintenance <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
