<!-- Start Footer Area -->
<footer class="footer-area {{location == '/' ? '' : 'uk-dark'}} uk-footer">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-footer-widget justified-text">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo2.png" alt="logo">
                        </a>
                    </div>
                    <p>Votre partenaire de confiance et expert en marketing digital, offre des solutions web rapides et fiables, livrant des projets de haute qualité en un temps record.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Agence de Paris</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>78 Avenue des Champs Elysées <br> 75008 Paris <br> France</p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Agence de Bayonne</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>Rue d'Espagne <br> Bayonne <br> France <br> (Acquisition en cours) </p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li>Email : <a href="mailto:hello@Neoluxis.fr">hello&#64;neoluxis.fr</a></li>
                        <li>Téléphone : <a href="tel:+0987565465">0987565465</a></li>
                    </ul>
                    <ul class="social">
                       <!--<li><a href="#" target="_blank"><i class="flaticon-logo"></i></a></li>--> 
                        <li><a rootlink="/" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/neoluxis.fr/" target="_blank"><i class="flaticon-logo-1"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <p>© Neoluxis Digital - 2024. Tous droits réservés.</p>
                </div>

                <div class="item">
                    <ul>
                        <li><a rootlink="/">Termes & Conditions</a></li>
                        <li><a rootlink="/">Politique de Confidentialité</a></li>
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <div class="br-line"></div>
    <div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="shape"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="shape"></div>
</footer>
<!-- End Footer Area -->
