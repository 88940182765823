import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';

@Component({
  selector: 'app-business-startups',
  templateUrl: './business-startups.component.html',
  styleUrls: ['./business-startups.component.scss']
})
export class BusinessStartupsComponent implements OnInit, AfterViewInit {
  contactForm: FormGroup;
  @ViewChild('animatedText') animatedText!: ElementRef;

  // Variables pour le texte dynamique
  headerText: string = 'Accélérez Votre Croissance avec des';
  highlightText: string = 'Solutions Web';

  constructor(private fb: FormBuilder) {
    // Initialise EmailJS avec votre User ID
    emailjs.init('D8gKxIRFR40VKRPXi'); // Remplacez par votre User ID EmailJS

    // Création du formulaire avec FormBuilder
    this.contactForm = this.fb.group({
      from_name: ['', Validators.required],
      from_email: ['', [Validators.required, Validators.email]],
      from_phone: [''],
      from_subject: ['', Validators.required],
      message: ['', Validators.required],
      terms: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    // Vous pouvez mettre à jour le texte dynamique ici si nécessaire
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.animatedText) {
        this.animatedText.nativeElement.classList.add('highlight');
      }
    }, 4000); // Déclenchement après la fin de l'animation de frappe
  }

  // Fonction pour envoyer l'email via EmailJS
  sendEmail(): void {
    if (this.contactForm.valid) {
      const formData = this.contactForm.getRawValue();

      emailjs.send('neoluxis_digital_france', 'template_neoluxis_first', formData)
        .then((response) => {
          console.log('Succès:', response);
          alert('Votre message a été envoyé avec succès !');
          this.resetForm();
        }, (error) => {
          console.log('Erreur:', error);
          alert('Une erreur est survenue. Veuillez réessayer.');
        });
    } else {
      alert('Veuillez remplir tous les champs du formulaire correctement.');
    }
  }

  // Fonction pour réinitialiser le formulaire
  resetForm(): void {
    this.contactForm.reset({
      from_phone: '',
      terms: false
    });
  }
}
