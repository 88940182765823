import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-site-web-et-ecommerce',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './site-web-et-ecommerce.component.html',
  styleUrl: './site-web-et-ecommerce.component.scss'
})
export class SiteWebEtEcommerceComponent {

}
