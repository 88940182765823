<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>CONTACT</h1>
        <ul>
            <li><a routerLink="/">ACCUEIL</a></li>
            <li>CONTACT</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Entrons en Contact</span>
            <h2>Contactez-nous</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item uk-width-1-1">
                <form [formGroup]="contactForm" (ngSubmit)="sendEmail()" id="contactForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">

                        <!-- Nom -->
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" formControlName="from_name" placeholder="Nom*" required>
                        </div>

                        <!-- Email -->
                        <div class="item uk-margin">
                            <input type="email" class="uk-input" formControlName="from_email" placeholder="E-mail*" required>
                        </div>

                        <!-- Téléphone -->
                        <div class="item uk-margin">
                            <input type="tel" class="uk-input" formControlName="from_phone" placeholder="Téléphone*">
                        </div>

                        <!-- Sujet -->
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" formControlName="from_subject" placeholder="Sujet*" required>
                        </div>

                        <!-- Votre entreprise -->
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" formControlName="company" placeholder="Votre entreprise*" required>
                        </div>

                        <!-- Votre budget -->
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" formControlName="budget" placeholder="Votre budget*">
                        </div>
                    </div>

                    <!-- Message -->
                    <div class="item uk-width-1-1">
                        <textarea formControlName="message" class="uk-textarea" cols="30" rows="4" placeholder="Votre message" required></textarea>
                    </div>

                    <!-- Terms & Conditions -->
                    <div class="checkbox-boxes">
                        
                        <label><input class="uk-checkbox" type="checkbox" formControlName="terms" required> J'accepte les <a routerLink="/contact">termes</a> et la <a routerLink="/contact">politique de confidentialité</a></label>
                      </div>

                    <!-- Submit Button -->
                    <div class="item uk-width-1-1">
                        <button type="submit" class="uk-button uk-button-default">Envoyer le Message</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<section class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <div class="map-img">
                <img src="assets/img/map.png" alt="map">

                <div class="location uk-location1">
                    <a href="#" class="active">
                        <div class="location-info">
                            <h5>AGENCE DE PARIS</h5>
                            <span>AVENUE DES CHAMPS ELYSEES</span>
                        </div>
                    </a>
                </div>

                <div class="location uk-location2">
                    <a href="#">
                        <div class="location-info">
                            <h5>AGENCE DE BAYONNE</h5>
                            <span>RUE D'ESPAGNE</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Map Area -->