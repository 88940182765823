import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  location: any;
  routerSubscription: any;

  contactForm: FormGroup;

  constructor(private router: Router, private fb: FormBuilder) {
    // Initialise EmailJS avec votre User ID
    emailjs.init('D8gKxIRFR40VKRPXi'); // Remplacez par votre User ID EmailJS

    // Création du formulaire avec FormBuilder
    this.contactForm = this.fb.group({
      from_name: ['', Validators.required],
      from_email: ['', [Validators.required, Validators.email]],
      from_phone: [''],
      from_subject: ['', Validators.required],
      message: ['', Validators.required],
      terms: [false, Validators.requiredTrue]
    });
  }

  ngOnInit() {
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        $('.uk-preloader').fadeIn();
      }
    });

    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('../assets/js/custom.js');
        $('.uk-preloader').fadeOut();
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  sendEmail(): void {
    if (this.contactForm.valid) {
      // Obtenir les valeurs du formulaire sous forme d'objet
      const formData = this.contactForm.getRawValue();

      emailjs.send('neoluxis_digital_france', 'template_neoluxis_first', formData)
        .then((response) => {
          console.log('Succès:', response);
          alert('Votre message a été envoyé avec succès !');
          this.resetForm(); // Réinitialise le formulaire après envoi
        }, (error) => {
          console.log('Erreur:', error);
          alert('Une erreur est survenue. Veuillez réessayer.');
        });
    } else {
      alert('Veuillez remplir tous les champs du formulaire correctement.');
    }
  }

  resetForm(): void {
    this.contactForm.reset({
      from_phone: '',
      terms: false
    });
  }
}
