<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Hébergement</h1>
        <ul>
            <li><a routerLink="/">Accueil</a></li>
            <li><a routerLink="/services">SERVICES</a></li>
            <li>Hébergement</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="justified-text services-details-desc">
                        <h3>Hébergement</h3>
                        <p>Chez Neoluxis Digital, nous savons combien il est crucial d'avoir un hébergement web fiable et sécurisé pour la réussite de votre entreprise en ligne. Nos solutions d'hébergement sont conçues pour offrir une performance optimale, une sécurité renforcée et une disponibilité maximale.</p>

                        <p>Nos offres d'hébergement incluent des serveurs partagés, des serveurs dédiés et des solutions d'hébergement cloud, toutes adaptées à vos besoins spécifiques. Que vous ayez un petit site vitrine ou une grande boutique en ligne, nous avons la solution idéale pour vous.</p>

                        <div class="services-image-slides owl-carousel owl-theme">
                            

                            <div class="item">
                                <img src="assets/img/hebergement-2.jpg" alt="Hébergement Image">
                            </div>

                            <div class="item">
                                <img src="assets/img/hebergement-1.jpg" alt="Hébergement Image">
                            </div>

                            
                        </div>

                        <h3>Pourquoi Choisir Ce Service</h3>
                        <p>Nos solutions d'hébergement offrent des performances rapides, une sécurité avancée et un support technique disponible 24/7 pour garantir que votre site web fonctionne toujours de manière optimale.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Hébergement Partagé</li>
                            <li><i class="flaticon-tick"></i> Serveurs Dédiés</li>
                            <li><i class="flaticon-tick"></i> Hébergement Cloud</li>
                            <li><i class="flaticon-tick"></i> Sécurité Avancée</li>
                            <li><i class="flaticon-tick"></i> Support Technique 24/7</li>
                        </ul>

                        <blockquote class="blockquote">
                            <p>Notre priorité est de fournir des solutions d'hébergement fiables et performantes pour garantir le succès en ligne de nos clients. Nous sommes dédiés à offrir un service exceptionnel et un support continu.</p>
                        </blockquote>

                        <h3>Les Bénéfices de Nos Services</h3>
                        <p>En choisissant nos services d'hébergement, vous bénéficiez d'une infrastructure de pointe, d'une sécurité renforcée et d'un support technique expert. Nous nous assurons que votre site est toujours disponible et performant, quelles que soient les circonstances. Nous travaillons en partenariat avec les principaux fournisseurs de cloud tels que AWS, Azure, Google Cloud et OVH pour garantir une infrastructure de haute qualité.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels types d'hébergement proposez-vous ?
                                    </a>

                                    <p class="accordion-content show">Nous proposons des solutions d'hébergement partagé, des serveurs dédiés et des solutions d'hébergement cloud pour répondre à tous les besoins de nos clients.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Comment garantissez-vous la sécurité de mes données ?
                                    </a>

                                    <p class="accordion-content">Nous utilisons des mesures de sécurité avancées, y compris le chiffrement des données, les pare-feu et les sauvegardes régulières, pour garantir la protection de vos informations.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Offrez-vous un support technique ?
                                    </a>

                                    <p class="accordion-content">Oui, notre équipe de support technique est disponible 24/7 pour répondre à toutes vos questions et résoudre rapidement tout problème technique.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Qu'est-ce qui rend vos services d'hébergement différents ?
                                    </a>

                                    <p class="accordion-content">Nous offrons une performance optimale, une sécurité renforcée et un support technique exceptionnel. Nos solutions d'hébergement sont conçues pour répondre aux besoins spécifiques de chaque client.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Rechercher...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/expertises/hebergement" class="active">Hébergement <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/maintenance" >Maintenance <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/site-web-et-ecommerce">Création de Sites Internet <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/developpement-web">Développement Web <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/publicite-seo-sea">SEO & SEA <i class="flaticon-right"></i></a></li>
                            
                            <li><a routerLink="/expertises/logo-and-print">Logo & Print <i class="flaticon-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Téléchargements</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a routerLink="#">Guide des Stratégies de Marketing Digital Efficaces<i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Complet d'Optimisation SEO & SEA <i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Avancé de Développement Web & Maintenance <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
