<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Neoluxis Digital</h1>
        <ul>
            <li><a routerLink="/">ACCUEIL</a></li>
            <li>À PROPOS DE NOUS</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>L'AGENCE</span>
                        <h2>Bienvenue chez Neoluxis Digital</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="justified-text about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Agir Vite, Livrer Fiable</h3>
                        <p>Chez Neoluxis Digital, nous transformons vos idées en succès grâce à notre expertise en technologie et créativité. Nous accompagnons startups et PME dans leur croissance digitale avec des services de marketing, développement web, hébergement et maintenance. Notre atout majeur ? Une livraison rapide et fiable, surpassant les standards des agences classiques.</p>
                        
                        <p>Notre équipe d'experts passionnés excelle dans leurs domaines respectifs, garantissant des résultats exceptionnels à chaque projet. Faites confiance à Neoluxis Digital pour une transformation digitale réussie et découvrez comment nous pouvons propulser votre entreprise vers de nouveaux horizons.</p>

                        <a routerLink="/contact" class="uk-button uk-button-default">Contactez-nous</a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="assets/img/Neoluxis-marketing-social-media.png" class="about-img1 hide-on-mobile" alt="about-img">
              
                    <img src="assets/img/1.png" class="shape-img" alt="shape">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->


<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-section uk-feedback">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="feedback-img">
                    <img src="assets/img/neoluxis-digital-marketing.png" alt="neoluxis-digital-marketing">

                    <img src="assets/img/1.png" class="shape-img" alt="image">

                    
                </div>
            </div>

            <div class="item">
                <div class="feedback-inner">
                    <div class="uk-section-title section-title">
                        <span>CE qui fait la difference</span>
                        <h2>Notre Philosophie </h2>
                        <div class="bar"></div>
                    </div>

                    <div class="justified-text feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback">
                            <i class="flaticon-quality"></i>
                            <div class="client">
                                <h3>Innover pour Connecter </h3>
                            </div>
                    
                            <p>Chez Neoluxis Digital, notre mission est d'innover pour créer des connexions significatives. Nous utilisons les nouvelles technologies et la créativité pour transformer les idées en expériences digitales captivantes. Chaque projet commence par une compréhension profonde de vos objectifs, de votre public et de ce qui rend votre entreprise unique. Nous croyons que l'innovation naît de la collaboration et de l'écoute active de nos clients.</p>

                            
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quality"></i>
                            <div class="client">
                                <h3>Agir Vite et Livrer Fiable </h3>
                            </div>
                    
                            <p>Nous comprenons l'importance du temps dans le monde des affaires. C'est pourquoi nous nous engageons à livrer des projets de haute qualité dans les délais les plus courts possibles. Notre méthodologie agile nous permet de répondre rapidement aux besoins changeants de nos clients tout en maintenant un haut niveau de qualité et de fiabilité. Vous pouvez compter sur Neoluxis Digital pour fournir des solutions efficaces et fiables, à chaque fois.</p>

                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Partner 
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-one.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-two.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-three.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-four.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-five.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner-six.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
 End Partner Area -->

<!-- Start Team 
<section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Our Team</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">View All</a>
        </div>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team2.jpg" alt="image">

            <div class="team-content">
                <h3>David Warner</h3>
                <span>UX/UI Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team3.jpg" alt="image">

            <div class="team-content">
                <h3>Emili Lucy</h3>
                <span>Project Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team4.jpg" alt="image">

            <div class="team-content">
                <h3>Steven Smith</h3>
                <span>Marketing Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team5.jpg" alt="image">

            <div class="team-content">
                <h3>Steve Eva</h3>
                <span>Creative Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>
    </div>
</section>
 End Team Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <h3>Abonnez-vous à notre newsletter</h3>
            </div>

            <div class="item">
                <form class="newsletter-form">
                    <input type="email" class="uk-input" placeholder="nom@gmail.com" name="EMAIL" required autocomplete="off">

                    <button type="submit" class="uk-button uk-button-default">S'abonner maintenant</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>
</section>
<!-- End Subscribe Area -->
