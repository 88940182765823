<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services de Publicité SEO & SEA</h1>
        <ul>
            <li><a routerLink="/">ACCUEIL</a></li>
            <li><a routerLink="/services">SERVICES</a></li>
            <li>Publicité SEO & SEA</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="justified-text services-details-desc">
                        <h3>Publicité SEO & SEA</h3>
                        <p>Chez Neoluxis Digital, nous proposons des services complets de publicité SEO (Search Engine Optimization) et SEA (Search Engine Advertising) pour maximiser la visibilité de votre entreprise sur les moteurs de recherche et les réseaux sociaux. Nos stratégies sont conçues pour attirer, engager et convertir votre audience cible en clients fidèles.</p>

                        <h3>Stratégies SEO</h3>
                        <p>Nos experts en SEO utilisent des techniques avancées pour améliorer le classement de votre site web sur les moteurs de recherche. Nous optimisons le contenu, la structure du site et les backlinks pour garantir une visibilité maximale.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Audit SEO complet</li>
                            <li><i class="flaticon-tick"></i> Optimisation on-page et off-page</li>
                            <li><i class="flaticon-tick"></i> Recherche et intégration de mots-clés</li>
                            <li><i class="flaticon-tick"></i> Création de contenu optimisé SEO</li>
                        </ul>

                        <h3>Stratégies SEA</h3>
                        <p>Notre équipe SEA crée et gère des campagnes publicitaires payantes sur Google Ads et d'autres plateformes pour attirer immédiatement du trafic qualifié vers votre site web.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Campagnes Google Ads</li>
                            <li><i class="flaticon-tick"></i> Publicité display</li>
                            <li><i class="flaticon-tick"></i> Remarketing</li>
                            <li><i class="flaticon-tick"></i> Optimisation du coût par clic (CPC)</li>
                        </ul>

                        <h3>Publicité sur les Réseaux Sociaux</h3>
                        <p>Nous exploitons la puissance des réseaux sociaux pour promouvoir votre marque. Nos campagnes publicitaires sont personnalisées pour atteindre votre public cible sur Facebook, Instagram, TikTok, LinkedIn, et plus encore.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Publicités Facebook et Instagram</li>
                            <li><i class="flaticon-tick"></i> Annonces TikTok</li>
                            <li><i class="flaticon-tick"></i> Campagnes LinkedIn</li>
                            <li><i class="flaticon-tick"></i> Publicité YouTube</li>
                        </ul>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/seo1.jpg" alt="Publicité SEO & SEA">
                            </div>
                            <div class="item">
                                <img src="assets/img/seo2.jpg" alt="Publicité sur les Réseaux Sociaux">
                            </div>
                            <div class="item">
                                <img src="assets/img/seo1.jpg" alt="Marketing Digital">
                            </div>
                        </div>

                        <h3>Pourquoi Choisir Nos Services</h3>
                        <p>En choisissant Neoluxis Digital, vous bénéficiez d'une expertise approfondie et de solutions personnalisées pour maximiser votre retour sur investissement publicitaire.</p>

                        <blockquote class="blockquote">
                            <p>"Optimisez votre visibilité en ligne et générez plus de conversions avec les stratégies publicitaires innovantes de Neoluxis Digital."</p>
                        </blockquote>

                        <h3>Les Bénéfices de Nos Services</h3>
                        <p>En collaborant avec nous, vous bénéficiez d'une analyse détaillée, d'un suivi rigoureux et de rapports transparents pour évaluer l'efficacité de vos campagnes. Nous nous assurons que chaque euro investi rapporte un maximum de résultats. Nous travaillons avec des partenaires cloud tels qu'AWS, Azure, Google, OVH, etc., pour garantir les meilleures performances possibles.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Visibilité accrue</li>
                            <li><i class="flaticon-tick"></i> Trafic qualifié</li>
                            <li><i class="flaticon-tick"></i> ROI optimisé</li>
                            <li><i class="flaticon-tick"></i> Support dédié</li>
                        </ul>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quelle est la différence entre SEO et SEA ?
                                    </a>
                                    <p class="accordion-content show">Le SEO améliore le classement organique de votre site sur les moteurs de recherche, tandis que le SEA utilise des annonces payantes pour attirer du trafic immédiatement.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels réseaux sociaux utilisez-vous pour la publicité ?
                                    </a>
                                    <p class="accordion-content">Nous utilisons Facebook, Instagram, TikTok, LinkedIn, et YouTube pour créer des campagnes publicitaires ciblées et efficaces.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Offrez-vous des services de remarketing ?
                                    </a>
                                    <p class="accordion-content">Oui, nous proposons des services de remarketing pour recapturer l'intérêt des visiteurs qui ont déjà interagi avec votre site ou vos annonces.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels avantages apportent les publicités sur les réseaux sociaux ?
                                    </a>
                                    <p class="accordion-content">Les publicités sur les réseaux sociaux permettent de cibler des audiences spécifiques avec des messages personnalisés, augmentant ainsi l'engagement et les conversions.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/expertises/publicite-seo-sea" class="active">Publicité SEO & SEA <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/site-web-et-ecommerce">Création de Sites Internet et E-commerce <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/developpement-web">Développement Web <i class="flaticon-right"></i></a></li>
                            
                            <li><a routerLink="/expertises/hebergement">Hébergement <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/maintenance">Maintenance <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/logo-and-print">Logo & Print <i class="flaticon-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Téléchargements</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a routerLink="#">Guide des Stratégies de Marketing Digital Efficaces<i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Complet d'Optimisation SEO & SEA <i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Avancé de Développement Web & Maintenance <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->
