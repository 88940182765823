import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-logo-and-print',
  standalone: true,
  imports: [RouterModule,CommonModule],
  templateUrl: './logo-and-print.component.html',
  styleUrl: './logo-and-print.component.scss'
})
export class LogoAndPrintComponent {

}
