import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;

  constructor(private fb: FormBuilder) {
    emailjs.init('D8gKxIRFR40VKRPXi'); // Remplace par ton User ID EmailJS

    this.contactForm = this.fb.group({
      from_name: ['', Validators.required],
      from_email: ['', [Validators.required, Validators.email]],
      from_phone: [''],
      from_subject: ['', Validators.required],
      company: ['', Validators.required],
      budget: [''],
      message: ['', Validators.required],
      terms: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {}

  sendEmail(): void {
    if (this.contactForm.valid) {
      emailjs.sendForm('neoluxis_digital_france', 'template_neoluxis_second', '#contactForm')
        .then((response) => {
          console.log('Succès:', response);
          alert('Votre message a été envoyé avec succès !');
          this.resetForm();
        }, (error) => {
          console.log('Erreur:', error);
          alert('Une erreur est survenue. Veuillez réessayer.');
        });
    } else {
      alert('Veuillez remplir tous les champs du formulaire correctement.');
    }
  }

  resetForm(): void {
    this.contactForm.reset({
      from_phone: '',
      terms: false
    });
  }
}
