<!-- Start Main Banner -->
<div class="banner-section">
    <div class="uk-container-expand">
      <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
        <div class="item">
          <div class="banner-content">
            <div class="d-table">
              <div class="d-table-cell">
                <div class="fluid-text-hover">
                  <div class="content ">
                    <h1>{{ headerText }} <span>{{ highlightText }}</span></h1>
                    <p>Neoluxis Digital offre des services web de premier ordre, garantissant la livraison de votre projet en moins d'une semaine, plus rapide qu'une agence de marketing classique. Faites confiance à notre équipe pour une exécution rapide et efficace.</p>
                    <div class="btn-box">
                      <a href="" class="uk-button uk-button-default" (click)="sendEmail()">Prendre un Rendez-vous</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="item">
          <div class="banner-image">
            <img src="assets/img/banner-img1.jpg" alt="image">
          </div>
        </div>
      </div>
    </div>
  </div>
  


<!-- End Main Banner -->

<!-- Start Services Area -->

<section class="uk-services services-section uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Nos Services</span>
            <h2>Solutions Web Modernes et Spécialisées Livrées Rapidement</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            
            <!-- Création de Sites Internet -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-website"></i>
                    </div>
                    <h3>Création de Sites Internet</h3>
                    <ul>
                        <li>Sites vitrines</li>
                        <li>Sites e-commerce</li>
                        <li>Pages de vente</li>
                        <li>Sites institutionnels</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/site-web-et-ecommerce" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- Développement Web -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-coding"></i>
                    </div>
                    <h3>Développement Web</h3>
                    <ul>
                        <li>Intégration de CMS</li>
                        <li>Frontend/Backend</li>
                        <li>Applications spécifiques</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/developpement-web" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- SEO & SEA -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>SEO & SEA</h3>
                    <ul>
                        <li>Audit SEO</li>
                        <li>Campagnes SEA</li>
                        <li>Optimisation de contenu</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/publicite-seo-sea" class="link uk-position-cover"></a>
                </div>
            </div>
            
          
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
         
            <!-- Hébergement -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-server"></i>
                    </div>
                    <h3>Hébergement</h3>
                    <ul>
                        <li>Hébergement partagé</li>
                        <li>Serveurs dédiés</li>
                        <li>Hébergement cloud</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/hebergement" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- Maintenance -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-maintenance"></i>
                    </div>
                    <h3>Maintenance</h3>
                    <ul>
                        <li>Mises à jour techniques</li>
                        <li>Support client</li>
                        <li>Sauvegardes et sécurité</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/maintenance" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- Logo & Print -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-design"></i>
                    </div>
                    <h3>Logo & Print</h3>
                    <ul>
                        <li>Création de logos</li>
                        <li>Design de cartes de visite</li>
                        <li>Articles promotionnels</li>
                    </ul>
                    <i class="flaticon-right link-btn"></i>
                    <a routerLink="/expertises/logo-and-print" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->



<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start About Area -->
<section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-image">
                    <img src="assets/img/about3.jpg" alt="image" class="hide-on-mobile">
                    <img src="assets/img/about4.png" alt="image">
                </div>
            </div>

            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>À propos de nous</span>
                        <h2>Rapides et fiables, nous faisons de votre satisfaction notre priorité.</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>

                        
                        <h3>Votre partenaire pour une transformation digitale réussie</h3>
                        <div class="justified-text">
                            <p>Chez Neoluxis Digital, nous fournissons des services complets de création de sites internet, développement web, SEO/SEA, hébergement, maintenance, et design (logo & print) aux PME et aux indépendants à la recherche d'un partenaire fiable pour leurs besoins en transformation digitale. Nous travaillons avec vous, pas pour vous, en utilisant nos vastes ressources pour garantir votre succès.</p>
                        <p>Notre équipe expérimentée et passionnée vit et respire le développement web et le marketing digital. Notre fierté réside dans notre rapidité et la satisfaction de nos clients, car nous croyons que des résultats rapides et fiables sont essentiels pour votre réussite.</p>

                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Process Area -->
<section class="process-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Processus</span>
            <h2>Des étapes simples pour réaliser votre projet</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item justified-text">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="receiver"></i>
                    </div>
                    <h3>Contactez-nous</h3>
                    <p>Expliquez-nous vos besoins via téléphone, email ou notre formulaire en ligne. Nous vous guiderons vers les meilleures solutions.</p>
                </div>
            </div>

            <div class="item justified-text">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cart"></i>
                    </div>
                    <h3>Confirmez votre projet</h3>
                    <p>Confirmez votre projet et notre équipe analysera vos besoins spécifiques pour élaborer une stratégie sur mesure.</p>
                </div>
            </div>

            <div class="item justified-text">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cog"></i>
                    </div>
                    <h3>Design & développement</h3>
                    <p>Notre équipe mettra en œuvre la stratégie définie pour créer une solution web attrayante et fonctionnelle.</p>
                </div>
            </div>

            <div class="item justified-text">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="home"></i>
                    </div>
                    <h3>Livraison & support</h3>
                    <p>Profitez de votre site web livré rapidement. Nous offrons un support continu et des services de maintenance pour assurer le bon fonctionnement de votre site.</p>
                </div>
            </div>

            <div class="item justified-text">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
<!-- End Process Area -->


<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Feedback Area 
<section id="testimonials" class="feedback-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Témoignages</span>
            <h2>Nous avons de superbes avis de nos clients</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-feedback-item">
                    <i class="flaticon-quote"></i>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="client-info">
                        <img src="assets/img/client1.png" alt="image">
                        <h3>Jason Statham</h3>
                        <span>CTO chez Envato</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <i class="flaticon-quote"></i>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="client-info">
                        <img src="assets/img/client2.png" alt="image">
                        <h3>Sarah Taylor</h3>
                        <span>CTO chez EnvyTheme</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-feedback-item">
                    <i class="flaticon-quote"></i>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="client-info">
                        <img src="assets/img/client3.png" alt="image">
                        <h3>Alister Cook</h3>
                        <span>CTO chez Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image">
    </div>
</section>
-->
<!-- End Feedback Area 

<div class="separate">
    <div class="br-line"></div>
</div>
-->

<!-- Start Project Area 
<section id="project" class="project-section uk-project uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Projets 2024</span>
            <h2>Nos dernières réalisations</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-project-box">
                    <a routerLink="#" class="project-img">
                        <img src="assets/img/Optimiser-Votre-Strategie-de-Publicite-sur-les-Reseaux-Sociaux-en-2024.png" alt="Optimiser-Votre-Stratégie-de-Publicite-sur-les-Reseaux-Sociaux-en-2024">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="#">Développement</a></h3>
                        <ul>
                            <li><a routerLink="#">Web</a></li>
                            <li><a routerLink="#">Mobile</a></li>
                        </ul>
                       
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project2.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">Architecture</a></h3>
                        <ul>
                            <li><a href="#">Domicile</a></li>
                            <li><a href="#">Pont</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a routerLink="/project-details" class="project-img">
                        <img src="assets/img/project3.jpg" alt="image">
                    </a>

                    <div class="project-content">
                        <h3><a routerLink="/project-details">UX/UI Design</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Mobile</a></li>
                        </ul>
                        <a routerLink="/project-details" class="details-btn"><i uk-icon="plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image">
    </div>
</section>
 End Project Area -->

<!-- End Funfacts Area -->

<!-- Start Blog Area -->
<section id="blog" class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Notre Blog d'Entreprise</span>
            <h2>Restez Informé avec les Dernières Astuces </h2>
            <div class="bar"></div>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog/seo-local-astuces">
                        <img src="assets/img/Optimiser-Votre-Strategie-de-Publicite-sur-les-Reseaux-Sociaux-en-2024.png" alt="Optimiser-Votre-Stratégie-de-Publicite-sur-les-Reseaux-Sociaux-en-2024">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">30 Juillet</span>
                    <h3><a routerLink="/blog/seo-local-astuces">Comment Optimiser le SEO Local de Votre Site Web en 2024 : Stratégies et Astuces</a></h3>
                    <a routerLink="/blog/seo-local-astuces" class="read-more">Lire Plus</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog/seo-social-ads-facebook-instagram-snap">
                        <img src="assets/img/Optimiser-le-SEO-Local-de-Votre-Site-Web-Strategies-et-Astuces.png" alt="Optimiser-le-SEO-Local-de-Votre-Site-Web-Strategies-et-Astuces">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">20 Avril</span>
                    <h3><a routerLink="/blog/seo-social-ads-facebook-instagram-snap">Optimiser Votre Stratégie de Publicité sur les Réseaux Sociaux en 2024 : Facebook, TikTok, Instagram, Snapchat et Google</a></h3>
                    <a routerLink="/blog/seo-social-ads-facebook-instagram-snap" class="read-more">Lire Plus</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog/e-commerce-tendances-strategies-technique-2024">
                        <img src="assets/img/E-commerce-en-2024-Tendances-Strategies-et-Techniques-pour-Reduire-Abandon-de-Panier.png" alt="E-commerce-en-2024-Tendances-Stratégies-et-Techniques-pour-Réduire-Abandon-de-Panier">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">11 Juin</span>
                    <h3><a routerLink="/blog/e-commerce-tendances-strategies-technique-2024">Le E-commerce en 2024 : Tendances, Stratégies et Techniques pour Réduire l'Abandon de Panier</a></h3>
                    <a routerLink="/blog/e-commerce-tendances-strategies-technique-2024" class="read-more">Lire Plus</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/bnp.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/chanel.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/cci-basque.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/gabon.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/jo.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/perinco.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/sncf.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/total.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Contact Area -->
<section id="contact" class="contact-section uk-contact">
   
    <div class="uk-creative-contact creative-contact-area ptb-100">
        <div class="uk-container">
            <div class="section-title-with-big-text top-zero">
                <span>C'EST PAR ICI</span>
                <h2>Vous avez des questions ?</h2>
            </div>
    
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                <div class="item">
                    <div class="creative-contact-image with-wrap-color">
                        <img src="assets/img/home-nine/contact.jpg" alt="image">
    
                        

                        <div class="info-content">
                            <h3>AGENCE BAYONNE | PARIS </h3>
                            <span>78 AVENUE DES CHAMPS ELYSEES, 75008 PARIS, FRANCE</span>
                            <ul>
                                <li>Email : <a href="mailto:hello@Neoluxis.fr">hello&#64;neoluxis.fr</a></li>
                                <li>Téléphone : <a href="tel:+33987565465 ">+33 987565465 </a></li>
                         
                            </ul>
                            
                        </div>
                    </div>
                </div>
    
                <div class="item uk-flex-middle">
                    <form [formGroup]="contactForm" (ngSubmit)="sendEmail()" id="contact-form">
                      <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                          <label class="uk-form-label" for="from_name">Nom</label>
                          <input type="text" id="from_name" formControlName="from_name" class="uk-input" placeholder="Fred SNCF" required>
                        </div>
                  
                        <div class="item uk-margin">
                          <label class="uk-form-label" for="from_email">Email</label>
                          <input type="email" id="from_email" formControlName="from_email" class="uk-input" placeholder="fred@sncf.com" required>
                        </div>
                  
                        <div class="item uk-margin">
                          <label class="uk-form-label" for="from_phone">Téléphone</label>
                          <input type="tel" id="from_phone" formControlName="from_phone" class="uk-input" placeholder="+33641979797">
                        </div>
                  
                        <div class="item uk-margin">
                          <label class="uk-form-label" for="from_subject">Sujet</label>
                          <input type="text" id="from_subject" formControlName="from_subject" class="uk-input" placeholder="Votre sujet" required>
                        </div>
                      </div>
                  
                      <div class="item">
                        <label class="uk-form-label" for="message">Votre message</label>
                        <textarea id="message" formControlName="message" class="uk-textarea" cols="30" rows="4" placeholder="Votre message ..." required></textarea>
                      </div>
                  
                      <div class="checkbox-boxes">
                        
                        <label><input class="uk-checkbox" type="checkbox" formControlName="terms" required> J'accepte les <a routerLink="/">termes</a> et la <a href="#">politique de confidentialité</a></label>
                      </div>
                  
                      <button type="submit" class="uk-button uk-button-default">Envoyer</button>
                    </form>
                  </div>
                  
                
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
